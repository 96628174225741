@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&family=Pacifico&family=Roboto:wght@500&display=swap");

:root {
  --primary: #3bc443;
  --light: #ffffff;
  --dark: #000000;
  --green-dark: #154718;
  --gold: #434401;
  --orange: #fc9200;
  --blue: #0a8ff5;
  --yellow: #e3d61c;
  --hero: #f1ff94;
  --legend: #f6f9e8;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
}

body {
  background-color: var(--legend);
}

.primary {
  color: var(--primary);
}

.navbar {
  background-color: var(--primary);
}

/* Navbar */
.navbar .nav-links {
  display: flex;
  align-items: center;
  justify-content: center;
  list-style-type: none;
}
.navbar .nav-links a {
  padding: 16px;
  width: 200px;
  text-align: center;
  position: relative;
  font-size: 2rem;
  color: var(--green-dark);
  font-family: "Pacifico", cursive;
  letter-spacing: 3px;
}
.navbar .nav-links a:hover {
  color: var(--legend);
  transition: 0.2s linear all;
}
.navbar .nav-links a.active {
  color: var(--legend);
}
.navbar .nav-links a::before {
  content: "";
  background-color: var(--legend);
  height: 6px;
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  transform: scaleX(0);
}
.navbar .nav-links a:hover::before {
  transition: 0.2s linear all;
  transform: scaleX(1);
}
.navbar .nav-links a.active::before {
  transform: scaleX(1);
}

/* Universal */
.profile,
.about,
.contact {
  margin-top: 180px;
}
.wrap-table {
  border: 10px solid var(--hero);
  border-radius: 100px;
  position: relative;
}
.wrap-table label {
  width: 600px;
  position: absolute;
  top: 0px;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 100px;
  background-color: var(--gold);
  padding: 15px 40px;
  font-size: 2rem;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  text-align: center;
  color: var(--hero);
}
.table-responsive {
  width: 100%;
  overflow-x: scroll;
}
.table-responsive::-webkit-scrollbar {
  height: 0;
}
table {
  padding: 60px 60px;
  margin: auto;
  white-space: nowrap;
}
table tr td {
  padding: 10px 20px;
  font-size: 2rem;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: var(--gold);
}
.follow {
  background-color: var(--blue);
  color: var(--light);
  border-radius: 10px;
  padding: 5px 24px;
  transition: 0.1s linear all;
}
.follow:hover,
.follow:focus {
  background-color: var(--dark);
}

/* Profile */
.wrap-img {
  margin: auto;
  width: 450px;
  height: 450px;
  border-radius: 50%;
  overflow: hidden;
  box-shadow: 0px 0px 0px 10px var(--legend), 0px 0px 0px 20px var(--hero);
}
.img-profile {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 50%;
  transform: scale(1);
  transition: 0.2s linear all;
}
.img-profile:hover {
  transform: scale(1.1);
}
/* Name */
.profile .name {
  margin-top: 80px;
  text-align: center;
  font-size: 2.4rem;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  letter-spacing: 2px;
  color: var(--dark);
}
/* Description */
.profile .wrap-description {
  margin: 80px auto;
  width: 55%;
  text-align: center;
}
.profile .wrap-description p {
  font-size: 2rem;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  line-height: 1.8;
  color: var(--gold);
}

/* About */
.about h1 {
  text-align: center;
  font-size: 2.4rem;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  letter-spacing: 2px;
  color: var(--dark);
}
.about .wrap-about {
  margin: 80px auto;
  width: 50%;
}

/* Contact */
.contact h1 {
  text-align: center;
  font-size: 2.4rem;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  letter-spacing: 2px;
  color: var(--dark);
}
.contact .wrap-contact {
  margin: 80px auto;
  width: 50%;
}

/* Footer */
.footer {
  background-color: var(--primary);
}
.footer h3 {
  font-size: 2rem;
  color: var(--green-dark);
  font-family: "Pacifico", cursive;
  letter-spacing: 3px;
  text-align: center;
  padding-bottom: 30px;
}

/* Laptop */
@media (max-width: 1600px) {
  .navbar .nav-links a {
    font-size: 1.4rem;
  }

  /* Universal */
  .profile,
  .about,
  .contact {
    margin-top: 120px;
  }
  .wrap-table {
    border: 4px solid var(--hero);
    border-radius: 10px;
  }
  .wrap-table label {
    width: 400px;
    border-radius: 10px;
    padding: 10px 20px;
    font-size: 1.4rem;
  }
  table {
    padding: 60px 10px;
  }
  table tr td {
    padding: 18px 0px;
    font-size: 1.3rem;
  }
  .follow {
    border-radius: 10px;
    padding: 5px 24px;
  }

  /* Profile */
  .wrap-img {
    width: 220px;
    height: 220px;
    box-shadow: 0px 0px 0px 6px var(--legend), 0px 0px 0px 12px var(--hero);
  }
  /* Name */
  .profile .name {
    margin-top: 40px;
    font-size: 1.4rem;
  }
  /* Description */
  .profile .wrap-description {
    margin: 40px auto;
    width: 95%;
  }
  .profile .wrap-description p {
    font-size: 1.3rem;
    line-height: 1.7;
    color: var(--gold);
  }

  /* About */
  .about h1 {
    font-size: 1.4rem;
  }
  .about .wrap-about {
    margin: 40px auto;
    width: 95%;
  }

  /* Contact */
  .contact h1 {
    font-size: 1.4rem;
  }
  .contact .wrap-contact {
    margin: 40px auto;
    width: 95%;
  }
}

/* Tablet */
@media (max-width: 768px) {
  .navbar .nav-links {
    display: flex;
    flex-direction: column;
  }

  .navbar .nav-links a {
    font-size: 1.2rem;
    width: 100%;
  }

  /* Universal */
  .profile,
  .about,
  .contact {
    margin-top: 100px;
  }
  .wrap-table {
    border: 4px solid var(--hero);
    border-radius: 10px;
  }
  .wrap-table label {
    border-radius: 10px;
    padding: 10px 20px;
    font-size: 1.2rem;
  }
  table {
    padding: 60px 10px;
  }
  table tr td {
    padding: 16px 0px;
    font-size: 1.1rem;
  }
  .follow {
    border-radius: 10px;
    padding: 5px 16px;
  }

  /* Profile */
  .wrap-img {
    width: 200px;
    height: 200px;
  }
  /* Name */
  .profile .name {
    font-size: 1.2rem;
  }
  /* Description */
  .profile .wrap-description p {
    font-size: 1.1rem;
    line-height: 1.7;
    color: var(--gold);
  }

  /* About */
  .about h1 {
    font-size: 1.2rem;
  }

  /* Contact */
  .contact h1 {
    font-size: 1.2rem;
  }
}

/* Android */
@media (max-width: 576px) {
  .navbar .nav-links a {
    font-size: 1.1rem;
  }

  /* Universal */
  .profile,
  .about,
  .contact {
    margin-top: 80px;
  }
  .wrap-table {
    border: 4px solid var(--hero);
    border-radius: 10px;
  }
  .wrap-table label {
    width: 250px;
    border-radius: 10px;
    padding: 10px 20px;
    font-size: 1.1rem;
  }
  table {
    padding: 60px 10px;
  }
  table tr td {
    padding: 8px 0px;
    font-size: 1rem;
  }
  .follow {
    border-radius: 10px;
    padding: 5px 10px;
  }

  /* Profile */
  .wrap-img {
    width: 200px;
    height: 200px;
  }
  /* Name */
  .profile .name {
    margin-top: 30px;
    font-size: 1.1rem;
  }
  /* Description */
  .profile .wrap-description {
    margin: 30px auto;
  }
  .profile .wrap-description p {
    font-size: 1rem;
    line-height: 1.6;
    color: var(--gold);
  }

  /* About */
  .about h1 {
    font-size: 1.1rem;
  }
  .about .wrap-about {
    margin: 50px auto;
  }

  /* Contact */
  .contact h1 {
    font-size: 1.1rem;
  }
  .contact .wrap-contact {
    margin: 30px auto;
  }
}
